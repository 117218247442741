import {Select} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import TaskItemsSelect from './TaskItemsSelect';

const propTypes = {
  name: PropTypes.string,
  nullValue: PropTypes.string,
  onChange: PropTypes.func,
  projects: PropTypes.array,
  value: PropTypes.string
};

export default function TaskSelectContent({
  name,
  nullValue = 'null',
  onChange,
  projects,
  value
}) {
  return (
    <Select
      defaultValue={value}
      name={name}
      onChange={onChange}
      value={value === nullValue ? value : value ? value.id : 0}
    >
      <option key="0" value="0">
        -
      </option>
      {projects.map((item) => (
        <TaskItemsSelect key={item.id} project={item} />
      ))}
    </Select>
  );
}

TaskSelectContent.propTypes = propTypes;
