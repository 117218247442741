import {Box, Button, Center} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import TimeEntry from 'containers/TimeEntry/TimeEntry';
import TimeentriesGroup from './TimeentriesGroup';

const propTypes = {
  onDeleteTimeEntry: PropTypes.func,
  onLoadMoreTimeEntries: PropTypes.func,
  onToogleModalTimeEntryOpen: PropTypes.func,
  timeentriesGroupedByDate: PropTypes.array
};

export default function TimeentriesContent({
  onDeleteTimeEntry,
  onLoadMoreTimeEntries,
  onToogleModalTimeEntryOpen,
  timeentriesGroupedByDate
}) {
  return (
    <>
      <TimeEntry />
      <Box w="100%">
        {timeentriesGroupedByDate?.dateintervals.map((item) => (
          <>
            <TimeentriesGroup
              key={item.numdate.toString()}
              dateinterval={item}
              onDeleteTimeEntry={onDeleteTimeEntry}
              onToogleModalTimeEntryOpen={onToogleModalTimeEntryOpen}
            />
          </>
        ))}
      </Box>
      <br />
      <Box spacing="24px" w="100%">
        <Center>
          <Button
            disabled={
              timeentriesGroupedByDate?.dateintervals.length >=
              timeentriesGroupedByDate?.aggregate.timeentry_interval_view
                .totalCount
            }
            onClick={() => {
              onLoadMoreTimeEntries();
            }}
          >
            Load More
          </Button>
        </Center>
      </Box>
    </>
  );
}

TimeentriesContent.propTypes = propTypes;
