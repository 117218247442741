import {Select} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  onChange: PropTypes.func,
  projects: PropTypes.array,
  value: PropTypes.number
};

export default function ProjectsSelectContent({onChange, projects, value}) {
  function onChangeValue(e) {
    const project = projects.find(
      (item) => item.id === parseInt(e.target.value)
    );

    onChange(project);
  }

  return (
    <Select onChange={onChangeValue} value={value}>
      <option key="0" value="0">
        -
      </option>
      {projects.map((item) => (
        <option key={item.id} value={item.id}>
          {item.name2}
        </option>
      ))}
    </Select>
  );
}

ProjectsSelectContent.propTypes = propTypes;
