import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOverlayClick: PropTypes.func,
  primaryButton: PropTypes.node,
  scrollBehavior: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string
};

export default function Modal({
  children,
  disabled = false,
  isOpen,
  onClose,
  primaryButton = undefined,
  size = 'xl',
  title,
  onOverlayClick,
  scrollBehavior
}) {
  const disabledStyle = disabled ? {opacity: 0.4} : null;

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      onOverlayClick={onOverlayClick}
      scrollBehavior={scrollBehavior}
      size={size}
    >
      <ModalOverlay />
      <ModalContent maxW="42rem">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody sx={disabledStyle}>{children}</ModalBody>
        <ModalFooter>
          <Button
            color="gray.600"
            isDisabled={disabled}
            mr="2"
            onClick={onClose}
          >
            cancel
          </Button>
          {primaryButton}
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
}

Modal.messages = ['Modal'];
Modal.propTypes = propTypes;
