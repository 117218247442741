import {useColorMode, useColorModeValue, IconButton} from '@chakra-ui/react';
import React from 'react';
import {FaMoon, FaSun} from 'react-icons/fa';

export const ColorModeSwitcher = (props) => {
  const {toggleColorMode} = useColorMode();
  const text = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  return (
    <IconButton
      aria-label={`Switch to ${text} mode`}
      color="current"
      fontSize="lg"
      icon={<SwitchIcon />}
      marginLeft="2"
      onClick={toggleColorMode}
      size="md"
      variant="ghost"
      {...props}
    />
  );
};
