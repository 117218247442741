import {gql, useQuery} from '@apollo/client';
import React, {useState} from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import TimeentriesReportsContent from './TimeentriesReportsContent';

const query = gql`
  query listTimeentries(
    $where: timeentry_view_bool_exp! = {}
    $limit: Int = 2500
  ) {
    aggregate: timeentry_view_aggregate(where: $where) {
      timeentriesview: aggregate {
        totalCount: count
        sum: sum {
          durationmin
        }
      }
    }
    timeentriesview: timeentry_view(
      order_by: {timeentry: {timefrom: desc_nulls_first}}
      where: $where
    ) {
      durationmin
      id
      numdate
      numweek
      timeentry: timeentry {
        description
        id
        timefrom
        timeto
        userid
        task {
          id
          name
          project {
            id
            name
            name2
          }
        }
        user {
          longname
        }
      }
    }
  }
`;

export default function TimeentriesReports() {
  const [filter, setFilter] = useState({
    project: undefined,
    from: undefined,
    to: undefined
  });

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  function getFilterToWithTime(to) {
    if (to !== undefined) {
      return `${to}T23:59:59`;
    } else {
      return to;
    }
  }

  // show all timeentries of the user or all timeentries for the projects that belong to his company
  const {data, error, loading} = useQuery(query, {
    variables: {
      where: {
        _and: [
          {timeentry: {task: {projectid: {_eq: filter?.project?.id}}}},
          {
            timeentry: {
              timefrom: {_gte: filter?.from},
              _and: {timeto: {_lte: getFilterToWithTime(filter?.to)}}
            }
          }
        ],
        _or: [
          {timeentry: {userid: {_eq: localStorage.getItem('userid')}}},
          {
            timeentry: {
              task: {
                project: {
                  company: {
                    companyuser: {
                      userid: {_eq: localStorage.getItem('userid')}
                    }
                  }
                }
              }
            }
          }
        ]
      }
    },
    fetchPolicy: 'network-only'
  });

  return (
    <AsyncContent error={error} loading={loading}>
      {() => (
        <TimeentriesReportsContent
          filter={filter}
          onChangeFilter={onChangeFilter}
          timeentries={data}
        />
      )}
    </AsyncContent>
  );
}
