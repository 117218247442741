import {Box, Text, useColorModeValue} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import TimeentriesItem from './TimeentriesItem';

const propTypes = {
  dateinterval: PropTypes.object,
  onDeleteTimeEntry: PropTypes.func,
  onToogleModalTimeEntryOpen: PropTypes.func
};

export default function TimeentriesGroup({
  dateinterval,
  onDeleteTimeEntry,
  onToogleModalTimeEntryOpen
}) {
  function formatNumDate(numdate) {
    const iso =
      numdate?.toString().substring(0, 4) +
      '-' +
      numdate?.toString().substring(4, 6) +
      '-' +
      numdate?.toString().substring(6, 8);

    const d = new Date(iso);
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    return d.toLocaleDateString('de-DE', options);
  }

  const bg = useColorModeValue('gray.200', 'green.900');

  return (
    <Box
      key={dateinterval.numdate}
      bgColor={bg}
      minH="24"
      minW="64"
      mt="2"
      overflow="hidden"
      p="3"
      rounded="md"
      w="100%"
    >
      <Box
        borderTopColor={
          dateinterval.numdate === 99999999 ? 'red.600' : 'grey.600'
        }
        borderTopWidth="5px"
        ml="-3"
        mr="-3"
        mt="-3"
        rounded="sm"
      />
      <Text as="b">{formatNumDate(dateinterval.numdate)}</Text>
      {dateinterval?.timeentries?.map((item) => (
        <>
          <TimeentriesItem
            key={item.id.toString()}
            onDeleteTimeEntry={onDeleteTimeEntry}
            onToogleModalTimeEntryOpen={onToogleModalTimeEntryOpen}
            timeentryview={item}
          />
        </>
      ))}
    </Box>
  );
}

TimeentriesGroup.propTypes = propTypes;
