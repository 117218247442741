import {Input, Text, Textarea} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import TaskSelect from 'containers/TaskSelect';

const propTypes = {
  handleChange: PropTypes.func,
  timeentry: PropTypes.object,
  timetoLong: PropTypes.bool
};

export default function TimeEntryFormItem({
  handleChange,
  timeentry,
  timetoLong = false
}) {
  return (
    <>
      {timetoLong ? (
        <Textarea
          maxlength="230"
          name="description"
          onChange={handleChange}
          placeholder="what have you done?"
          resize="vertical"
          value={timeentry.description}
        />
      ) : (
        <Input
          maxlength="230"
          name="description"
          onChange={handleChange}
          placeholder="what have you done?"
          value={timeentry.description}
        />
      )}

      <TaskSelect
        name="taskid"
        onChange={handleChange}
        value={timeentry.taskid}
      />
      <Input
        name="timefrom"
        onChange={handleChange}
        placeholder="Select Date and Time"
        size="md"
        type="datetime-local"
        value={timeentry.timefrom}
      />
      <Text>-</Text>
      <Input
        name="timeto"
        onChange={handleChange}
        placeholder="Select Date and Time"
        size="md"
        type={timetoLong ? 'datetime-local' : 'time'}
        value={timeentry.timeto}
      />
    </>
  );
}

TimeEntryFormItem.propTypes = propTypes;
