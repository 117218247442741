import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  project: PropTypes.object
};

export default function TaskItemsSelect({project}) {
  return (
    <optgroup label={project.name2}>
      {project.tasks.map((itemSub) => (
        <option key={project.id + '-' + itemSub.id} value={itemSub.id}>
          {itemSub.name}
        </option>
      ))}
    </optgroup>
  );
}

TaskItemsSelect.propTypes = propTypes;
