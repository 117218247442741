import {VStack, Input, Text, Button, useToast} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import useFormState from 'hooks/useFormState';

const propTypes = {
  onLogin: PropTypes.func,
  onLoginFinished: PropTypes.func
};

export default function LoginForm({onLogin, onLoginFinished}) {
  const formState = useFormState();
  const {values = {}, handleChange} = formState;
  const {pwd = '', user = ''} = values;

  const toast = useToast();

  function onSubmit() {
    const credentials = {
      user: values.user,
      pwd: values.pwd
    };

    return onLogin(credentials)
      .then((res) => {
        if (res?.data?.actionLogin?.isAuthenticated) {
          toast({
            title: 'Good',
            description: 'You have sucessfully logged in.',
            status: 'success',
            duration: 9000,
            isClosable: true
          });
          onLoginFinished(res);
        } else {
          toast({
            title: 'Good',
            description:
              'Login not sucessfull, maybe username or password is not correct.',
            status: 'warning',
            duration: 9000,
            isClosable: true
          });
        }
      })
      .catch(() => {
        toast({
          title: 'Problem',
          description: 'We could not login you - try later.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      });
  }

  return (
    <>
      <Text>Login</Text>
      <VStack>
        <Input
          name="user"
          onChange={handleChange}
          placeholder="user"
          value={user}
        />
        <Input
          name="pwd"
          onChange={handleChange}
          placeholder="password"
          type="password"
          value={pwd}
        />
        <Button colorScheme="blue" onClick={() => onSubmit()}>
          Login
        </Button>
      </VStack>
    </>
  );
}

LoginForm.propTypes = propTypes;
