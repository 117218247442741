import {Spinner, Text, Box} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  loadingMessage: PropTypes.string,
  size: PropTypes.string
};

export default function AsyncContentSpinner({loadingMessage, size = 'md'}) {
  return (
    <Box d="flex" justifyContent="center" w="100%">
      <Spinner size={size} />
      {loadingMessage && <Text>{loadingMessage}</Text>}
    </Box>
  );
}

AsyncContentSpinner.propTypes = propTypes;
