import {gql, useQuery, useMutation} from '@apollo/client';
import React, {useState} from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import ProjectDeliveriesContent from './ProjectDeliveriesContent';

const query = gql`
  query listProjectDeliveries(
    $where: project_delivery_bool_exp! = {}
    $limit: Int = 2500
  ) {
    aggregate: project_delivery_aggregate(where: $where) {
      projectdeliveries: aggregate {
        totalCount: count
      }
    }
    projectdeliveries: project_delivery(
      order_by: {project_user: {project: {name: asc}}}
      where: $where
    ) {
      id
      article {
        title
      }
      project_user {
        project {
          name
        }
        userlist {
          longname
        }
        company {
          title
        }
      }
      customer {
        name
        company {
          title
        }
      }
      chargingtype
    }
  }
`;

const queryCreateProjectDelivery = gql`
  mutation actionCreateProjectDelivery(
    $customerid: Int!
    $timeentryfilterFrom: String!
    $timeentryfilterTo: String!
  ) {
    actionCreateProjectDelivery(
      customerid: $customerid
      timeentryfilterFrom: $timeentryfilterFrom
      timeentryfilterTo: $timeentryfilterTo
    ) {
      message
      code
    }
  }
`;

const queryDeleteProjectDelivery = gql`
  mutation deleteProjectDelivery($id: Int!) {
    delete_project_delivery_by_pk(id: $id) {
      id
    }
  }
`;

export default function ProjectDeliveries() {
  const [filter, setFilter] = useState({
    project: undefined,
    from: undefined,
    to: undefined
  });

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  // for allContributors:
  // - list all records for the projects of my company
  // for supplierContributorsOnly:
  // - list all delivery records for the customers of that company
  const $listVariables = {
    where: {
      _or: [
        {
          _and: [
            {chargingtype: {_eq: 'allContributors'}},
            {
              project_user: {
                project: {
                  company: {
                    companyuser: {userid: {_eq: localStorage.getItem('userid')}}
                  }
                }
              }
            }
          ]
        },
        {
          _and: [
            {chargingtype: {_eq: 'supplierContributorsOnly'}},
            {
              customer: {
                company: {
                  companyuser: {userid: {_eq: localStorage.getItem('userid')}}
                }
              }
            }
          ]
        }
      ]
    }
  };

  const {data, error, loading} = useQuery(query, {
    variables: $listVariables,
    fetchPolicy: 'network-only'
  });

  const [
    onCreateProjectDeliveryMutation,
    {errorCreateProjectDelivery, loadinCreateProjectDelivery}
  ] = useMutation(queryCreateProjectDelivery);

  async function onCreateProjectDelivery(payload) {
    return onCreateProjectDeliveryMutation({
      variables: payload,
      refetchQueries: [
        {
          query,
          variables: $listVariables
        }
      ]
    });
  }

  const [
    isModalProjectDeliveryCreateOpen,
    setIsModalProjectDeliveryCreateOpen
  ] = useState(false);

  function onShowModalProjectDeliveryCreate() {
    setIsModalProjectDeliveryCreateOpen((prev) => !prev);
  }

  const [onDeleteProjectDeliveryMutation, {errorDelete, loadingDelete}] =
    useMutation(queryDeleteProjectDelivery);

  async function onDeleteProjectDelivery(id) {
    return onDeleteProjectDeliveryMutation({
      variables: {id},
      refetchQueries: [
        {
          query,
          variables: $listVariables
        }
      ]
    });
  }

  return (
    <AsyncContent
      error={error || errorCreateProjectDelivery || errorDelete}
      loading={loading || loadinCreateProjectDelivery || loadingDelete}
    >
      {() => (
        <>
          <ProjectDeliveriesContent
            filter={filter}
            isModalProjectDeliveryCreateOpen={isModalProjectDeliveryCreateOpen}
            onChangeFilter={onChangeFilter}
            onCreateProjectDelivery={onCreateProjectDelivery}
            onDeleteProjectDelivery={onDeleteProjectDelivery}
            onShowModalProjectDeliveryCreate={onShowModalProjectDeliveryCreate}
            projectdeliveries={data}
          />
        </>
      )}
    </AsyncContent>
  );
}
