import {Button, useToast} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'components/Modal';
import useFormState from 'hooks/useFormState';
import TimeEntryFormItem from './TimeEntryFormItem';

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOverlayClick: PropTypes.func,
  onUpdateTimeEntry: PropTypes.func,
  onUpdateTimeEntryFinish: PropTypes.func,
  timeentry: PropTypes.object
};

export default function TimeEntryModal({
  isOpen,
  onClose,
  onOverlayClick,
  onUpdateTimeEntry,
  onUpdateTimeEntryFinish,
  timeentry
}) {
  const toast = useToast();

  let isSubmitting = false; // @todo
  const isFormValid = true; // @todo

  function onSubmit(values) {
    const updateTimeentry = values;
    updateTimeentry.id = timeentry.id;

    return onUpdateTimeEntry(updateTimeentry)
      .then(() => {
        toast({
          title: 'success',
          status: 'success'
        });
        isSubmitting = true;
        onUpdateTimeEntryFinish(updateTimeentry);
      })
      .catch((e) =>
        toast({
          title: 'error',
          description: 'problem updating timeentry. ' + e,
          status: 'error',
          isClosable: true
        })
      );
  }

  const formState = useFormState(timeentry);
  const {handleChange, values} = formState;

  return (
    <Modal
      disabled={isSubmitting}
      isOpen={isOpen}
      onClose={onClose}
      onOverlayClick={onOverlayClick || onClose}
      primaryButton={
        <Button
          colorScheme="teal"
          disabled={!isFormValid}
          isLoading={isSubmitting}
          onClick={() => onSubmit(values)}
          type="button"
        >
          save
        </Button>
      }
      scrollBehavior="inside"
      title="Edit Timeentry"
    >
      <TimeEntryFormItem
        handleChange={handleChange}
        timeentry={values}
        timetoLong
      />
    </Modal>
  );
}

TimeEntryModal.propTypes = propTypes;
