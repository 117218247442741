import {gql, useQuery, useMutation} from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import InvoiceContent from './InvoiceContent';

const query = gql`
  query getInvoice($id: Int!) {
    invoice: invoice_by_pk(id: $id) {
      companyid
      currency
      customerid
      id
      invoicedate
      totalnet
      vatratio
      vatamount
      totalgross
      customername
      customeraddress
      company {
        title
      }
      timeentries: invoice_position(order_by: {description: asc}) {
        id
        description
        invoice_position_timeentry(
          order_by: {timeentry_view: {timeentry: {timefrom: asc}}}
        ) {
          timeentry_view {
            id
            durationmin
            numweek
            numdate
            timeentry {
              description
              user {
                longname
              }
              timefrom
              timeto
              task {
                name
                project {
                  name
                }
              }
            }
          }
        }
      }
      invoice_position {
        id
        description
        priceperpiece
        pricecurrency
        amountofpieces
        totalprice
        invoice_position_timeentry(
          order_by: {timeentry_view: {timeentry: {timefrom: asc}}}
        ) {
          timeentry_view {
            id
            durationmin
            numweek
            numdate
            timeentry {
              description
              user {
                longname
              }
              timefrom
              timeto
              task {
                name
                project {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const queryGetPdfInvoice = gql`
  mutation actionGetPdfInvoice($id: Int!) {
    actionGetPdfInvoice(invoiceid: $id) {
      code
      data
    }
  }
`;

const propTypes = {
  id: PropTypes.string
};

export default function Invoice({id}) {
  const {data, error, loading} = useQuery(query, {
    variables: {id},
    fetchPolicy: 'network-only'
  });

  const [onGetPdfInvoiceMutation, {errorGetPdfInvoice, loadinGetPdf}] =
    useMutation(queryGetPdfInvoice);

  async function onGetPdfInvoice() {
    return onGetPdfInvoiceMutation({
      variables: {id}
    });
  }

  return (
    <AsyncContent error={error} loading={loading}>
      {() => (
        <InvoiceContent
          invoice={data?.invoice}
          onGetPdfInvoice={onGetPdfInvoice}
        />
      )}
    </AsyncContent>
  );
}

Invoice.propTypes = propTypes;
