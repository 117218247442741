import {gql, useQuery} from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContentInline from 'components/AsyncContent/AsyncContentInline';
import ProjectsSelectContent from './ProjectsSelectContent';

export const query = gql`
  query listProject($userid: Int!) {
    projects: project(
      order_by: {name: asc}
      where: {project_user: {userid: {_eq: $userid}}}
    ) {
      id
      name
      name2
    }
  }
`;

const propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number
};

export default function ProjectsSelect({onChange, value}) {
  const {data, error, loading} = useQuery(query, {
    variables: {userid: localStorage.getItem('userid')},
    fetchPolicy: 'network-only'
  });

  function onChangeValue(project) {
    onChange(project);
  }

  return (
    <AsyncContentInline error={error} loading={loading}>
      {() => (
        <ProjectsSelectContent
          onChange={onChangeValue}
          projects={data?.projects}
          value={value}
        />
      )}
    </AsyncContentInline>
  );
}

ProjectsSelect.propTypes = propTypes;
