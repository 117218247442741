import {DeleteIcon} from '@chakra-ui/icons';
import {
  Button,
  useColorModeValue,
  useToast,
  Box,
  Spacer,
  Stack
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import Formater from 'utils/Formater';

const propTypes = {
  onDeleteTimeEntry: PropTypes.func,
  onToogleModalTimeEntryOpen: PropTypes.func,
  timeentryview: PropTypes.object
};

export default function TimeentriesItem({
  onDeleteTimeEntry,
  onToogleModalTimeEntryOpen,
  timeentryview
}) {
  const toast = useToast();

  function onDelete() {
    return onDeleteTimeEntry(timeentryview)
      .then(() => {
        toast({
          title: 'Good',
          description: "We've sucessfully deleted your record.",
          status: 'success',
          duration: 9000,
          isClosable: true
        });
      })
      .catch((e) => {
        toast({
          title: 'Problem',
          description: 'We could not delete your record. ' + e,
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      });
  }

  function trimDescription(txt) {
    if (txt && txt.length > 280) {
      return txt.substring(0, 280) + '...';
    } else {
      return txt;
    }
  }

  const bg = useColorModeValue('gray.100', 'gray.800');

  return (
    <Box
      key={timeentryview.id}
      bgColor={bg}
      minH="24"
      minW="64"
      mt="2"
      overflow="hidden"
      p="3"
      rounded="md"
      w="100%"
    >
      <Box
        borderTopColor="grey.600"
        borderTopWidth="5px"
        ml="-3"
        mr="-3"
        mt="-3"
        rounded="sm"
      />
      <Stack direction={['column', 'row']}>
        <Stack
          direction={['column', 'row']}
          onClick={() => onToogleModalTimeEntryOpen(timeentryview?.timeentry)}
          spacing="5"
          w="100%"
        >
          <Box w={['100%', '90']}>
            {trimDescription(timeentryview?.timeentry.description)}
          </Box>
          <Box w={['100%', '90']}>
            {timeentryview?.timeentry.task?.project?.name2} -{' '}
            {timeentryview?.timeentry.task?.name}
          </Box>
          <Box alignItems="right">
            {timeentryview?.timeentry.timefrom?.slice(11, 16)}-
            {timeentryview?.timeentry.timeto?.slice(11, 16)}
          </Box>
          <Spacer />
          <Box alignItems="right">
            {Formater.numberFormat(timeentryview?.durationmin / 60)}h
          </Box>
        </Stack>
        <Stack>
          <Box align="right" mr="4" w={['100%', '5']}>
            <Button
              onClick={() =>
                window.confirm(
                  'hold on for a second. do you want to DELETE that record?'
                ) && onDelete(timeentryview.timeentry)
              }
            >
              <DeleteIcon />
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

TimeentriesItem.propTypes = propTypes;
