import {HamburgerIcon} from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton
} from '@chakra-ui/react';
import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

export default function MainMenu() {
  const navigate = useNavigate();

  function logout() {
    localStorage.removeItem('jsonwebtoken');
    navigate('/logout');
  }

  // simple auth check, not perfekt because token is not validated
  let isAuthenticated = false;
  if (localStorage.getItem('jsonwebtoken')) {
    isAuthenticated = true;
  }

  return (
    <Menu>
      <MenuButton
        aria-label="Options"
        as={IconButton}
        icon={<HamburgerIcon />}
        variant="outline"
      />
      {isAuthenticated ? (
        <MenuList>
          <MenuItem command="⌘T" onClick={() => navigate('/timeentries')}>
            <Link to="/timeentries">My Timeentries</Link>
          </MenuItem>
          <MenuItem
            command="⌘T"
            onClick={() => navigate('/timeentriesreports')}
          >
            <Link to="/timeentriesreports">Reports</Link>
          </MenuItem>
          <MenuItem command="⌘T" onClick={() => navigate('/invoices')}>
            <Link to="/invoices">Invoices</Link>
          </MenuItem>
          <MenuItem>-</MenuItem>
          <MenuItem command="⌘T" onClick={() => navigate('/projects')}>
            <Link to="/projects">Projects</Link>
          </MenuItem>
          <MenuItem command="⌘T" onClick={() => navigate('/projectdeliveries')}>
            <Link to="/projectdeliveries">Charging Setup</Link>
          </MenuItem>
          <MenuItem command="⌘O" onClick={() => logout()}>
            Logout
          </MenuItem>
        </MenuList>
      ) : (
        <MenuList>
          <MenuItem onClick={() => navigate('/login')}>Login</MenuItem>
        </MenuList>
      )}
    </Menu>
  );
}
