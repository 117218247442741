import {Text} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  error: PropTypes.object
};

export default function AsyncContentError({error}) {
  let displayError;

  if (error.graphQLErrors?.length > 0) {
    displayError = error.graphQLErrors.map((graphQLError, i) => (
      <div key={i}>{graphQLError.message}</div>
    ));
  } else {
    displayError = JSON.stringify(error);
  }

  return (
    <Text as="div" weigth="medium">
      Error: {displayError}
    </Text>
  );
}

AsyncContentError.propTypes = propTypes;
