import {Select} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  customers: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default function CustomersSelectContent({
  customers,
  name,
  onChange,
  value
}) {
  return (
    <Select name={name} onChange={onChange} value={value}>
      <option key="0" value="0">
        -
      </option>
      {customers.map((item) => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ))}
    </Select>
  );
}

CustomersSelectContent.propTypes = propTypes;
