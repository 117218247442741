import {useToast, Button} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import ProjectForm from 'components/ProjectForm';
import useFormState from 'hooks/useFormState';

const propTypes = {
  onUpdateProject: PropTypes.func,
  onUpdateProjectFinish: PropTypes.func,
  project: PropTypes.object
};

export default function ProjectContent({
  onUpdateProject,
  onUpdateProjectFinish,
  project
}) {
  const toast = useToast();

  let isSubmitting = false; // @todo
  const isFormValid = true; // @todo

  function onSubmit(values) {
    const updateProject = values;
    updateProject.id = project.id;

    return onUpdateProject(updateProject)
      .then(() => {
        toast({
          title: 'success',
          status: 'success'
        });
        isSubmitting = true;
        onUpdateProjectFinish(updateProject);
      })
      .catch((e) =>
        toast({
          title: 'error',
          description: 'problem updating project. ' + e,
          status: 'error',
          isClosable: true
        })
      );
  }

  const formState = useFormState(project);
  const {handleChange, values} = formState;

  return (
    <>
      <ProjectForm handleChange={handleChange} project={values} />
      <br />
      <Button
        colorScheme="teal"
        disabled={!isFormValid}
        isLoading={isSubmitting}
        onClick={() => onSubmit(values)}
        type="button"
      >
        save
      </Button>
    </>
  );
}

ProjectContent.propTypes = propTypes;
