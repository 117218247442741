import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Flex,
  Box,
  Text
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import Formater from 'utils/Formater';

const propTypes = {
  positiontimeentries: PropTypes.object
};

export default function InvoiceTimeentries({positiontimeentries}) {
  function sumDurationMin(invoice_position_timeentry) {
    const sum = invoice_position_timeentry.reduce((a, b) => {
      const bSum = b?.timeentry_view?.durationmin || 0;
      return a + bSum;
    }, 0);
    return sum;
  }

  return (
    <>
      <>
        <p>{positiontimeentries?.description}</p>
        <TableContainer key={positiontimeentries.id}>
          <Table variant="simple">
            <TableCaption>Timeentries</TableCaption>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>From-To</Th>
                <Th>Task</Th>
                <Th>Description</Th>
                <Th>User</Th>
                <Th isNumeric>Duration [h]</Th>
              </Tr>
            </Thead>
            <Tbody>
              {positiontimeentries?.invoice_position_timeentry.map(
                (item, index) => (
                  <Tr key={index}>
                    <Td>{item.timeentry_view?.numdate}</Td>
                    <Td>
                      {item.timeentry_view?.timeentry?.timefrom?.slice(11, 16)}-
                      {item.timeentry_view?.timeentry?.timeto?.slice(11, 16)}
                    </Td>
                    <Td>{item.timeentry_view?.timeentry?.task.name}</Td>
                    <Td
                      css={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                      }}
                    >
                      {item.timeentry_view?.timeentry?.description}
                    </Td>
                    <Td>{item.timeentry_view?.timeentry?.user.longname}</Td>
                    <Td isNumeric>
                      {Formater.numberFormat(
                        item.timeentry_view?.durationmin / 60
                      )}
                    </Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex fontSize="lg" w="100%">
          <Box align="right" w="90%">
            <Text>Total [h]:</Text>
          </Box>
          <Box align="right" ml="1" mr="5" w="10%">
            <Text textDecoration="underline">
              {Formater.numberFormat(
                sumDurationMin(
                  positiontimeentries?.invoice_position_timeentry
                ) / 60
              )}
            </Text>
          </Box>
        </Flex>
      </>
    </>
  );
}

InvoiceTimeentries.propTypes = propTypes;
