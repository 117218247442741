import {Input, Text, Box} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  handleChange: PropTypes.func,
  project: PropTypes.object
};

export default function ProjectForm({handleChange, project}) {
  return (
    <>
      <Box>
        <Text as="label" fontSize="sm" fontWeight="medium">
          Worktitle
        </Text>
        <Input
          maxLength="230"
          name="name2"
          onChange={handleChange}
          placeholder="project name2"
          value={project.name2}
        />
      </Box>
      <br />
      <Box>
        <Text as="label" fontSize="sm" fontWeight="medium">
          Name on the Invoice
        </Text>
        <Input
          maxLength="230"
          name="name"
          onChange={handleChange}
          placeholder="project name"
          value={project.name}
        />
      </Box>
      <br />
      <Box>
        <Text as="label" fontSize="sm" fontWeight="medium">
          Budget [min]
        </Text>
        <Input
          maxLength="230"
          name="budgetmin"
          onChange={handleChange}
          placeholder="60"
          value={project.budgetmin}
        />
      </Box>
      <br />
      <Box>
        <Text as="label" fontSize="sm" fontWeight="medium">
          Completely Invoced, no more effort booking
        </Text>
        <Input
          maxLength="10"
          name="archived"
          onChange={handleChange}
          placeholder="false"
          value={project.archived}
        />
      </Box>
    </>
  );
}

ProjectForm.propTypes = propTypes;
