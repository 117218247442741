import {gql} from '@apollo/client';
import {useMutation} from '@apollo/react-hooks';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import LoginForm from './LoginForm';

const query = gql`
  mutation actionLogin($user: String!, $pwd: String!) {
    actionLogin(user: $user, pwd: $pwd) {
      isAuthenticated
      userid
      jsonwebtoken
    }
  }
`;

export default function Login() {
  const [onLoginMutation, {error, loading}] = useMutation(query);

  async function onLogin(credentials) {
    return onLoginMutation({
      variables: credentials
    });
  }

  const navigate = useNavigate();

  function onLoginFinished(res) {
    const isAuthenticated = res?.data?.actionLogin?.isAuthenticated === true;

    const token = res?.data?.actionLogin?.jsonwebtoken;
    const userid = res?.data?.actionLogin?.userid;

    if (isAuthenticated && token) {
      localStorage.setItem('jsonwebtoken', token);
      localStorage.setItem('userid', userid);
      navigate('/timeentries');
    } else {
      navigate('/login');
    }
  }

  return (
    <AsyncContent error={error} loading={loading}>
      {() => <LoginForm onLogin={onLogin} onLoginFinished={onLoginFinished} />}
    </AsyncContent>
  );
}
