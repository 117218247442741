import {gql, useQuery} from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContentInline from 'components/AsyncContent/AsyncContentInline';
import CustomersSelectContent from './CustomersSelectContent';

export const query = gql`
  query listCustomers($userid: Int!) {
    customers: customer(
      where: {company: {companyuser: {userid: {_eq: $userid}}}}
      order_by: {name: asc}
    ) {
      name
      id
    }
  }
`;

const propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number
};

export default function CustomersSelect({name, onChange, value}) {
  const {data, error, loading} = useQuery(query, {
    variables: {userid: localStorage.getItem('userid')},
    fetchPolicy: 'network-only'
  });

  return (
    <AsyncContentInline error={error} loading={loading}>
      {() => (
        <CustomersSelectContent
          customers={data?.customers}
          name={name}
          onChange={onChange}
          value={value}
        />
      )}
    </AsyncContentInline>
  );
}

CustomersSelect.propTypes = propTypes;
