import {DeleteIcon} from '@chakra-ui/icons';
import {Flex, useDisclosure, Box, Button, useToast} from '@chakra-ui/react';
import {createColumnHelper} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate, useMatch} from 'react-router-dom';
import {DataTable} from 'components/DataTable/DataTable';
import SidebarDrawer from 'components/SidebarDrawer';
import Invoice from 'containers/Invoice';
import InvoicesTopBar from './InvoicesTopBar';
// import InvoicesTopBar from './TimeentriesReportTopBar';

const propTypes = {
  filter: PropTypes.object.isRequired,
  invoices: PropTypes.object,
  onChangeFilter: PropTypes.func.isRequired,
  onDeleteInvoice: PropTypes.func,
  onShowModalInvoiceCreate: PropTypes.func
};

/*

  columnHelper.accessor('durationmin', {
    cell: (info) => (info.getValue() / 60)?.toFixed(2),
    header: 'Duration [h]',
    size: 150,
    meta: {
      isNumeric: true
    }
  }),

  */

export default function InvoicesContent({
  filter,
  invoices,
  onChangeFilter,
  onDeleteInvoice,
  onShowModalInvoiceCreate
}) {
  const navigate = useNavigate();

  function onClickRow(row) {
    return navigate(`/invoices/invoice/${row.getValue('id')}`);
  }

  const match = useMatch('/invoices/invoice/:id');

  const openSidebarDrawer = match?.params?.id != null;
  useDisclosure({isOpen: openSidebarDrawer});

  function onCloseSidebar() {
    // onClose();
    navigate('/invoices');
  }

  const toast = useToast();

  function onDelete(datatableItem) {
    return onDeleteInvoice(datatableItem.row.getValue('id'))
      .then(() => {
        toast({
          title: 'Good',
          description: "We've sucessfully deleted your invoice.",
          status: 'success',
          duration: 9000,
          isClosable: true
        });
      })
      .catch((e) => {
        toast({
          title: 'Problem',
          description: 'We could not delete your invoice. ' + e,
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      });
  }

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('invoicedate', {
      cell: (info) => info.getValue(),
      header: 'Invoicedate',
      size: 200
    }),
    columnHelper.accessor('num', {
      cell: (info) => info.getValue(),
      header: '#',
      size: 200
    }),
    columnHelper.accessor('customer.name', {
      cell: (info) => info.getValue(),
      header: 'Customer',
      size: 200
    }),
    columnHelper.accessor('currency', {
      cell: (info) => info.getValue(),
      header: 'Currency',
      size: 200
    }),
    columnHelper.accessor('company.title', {
      cell: (info) => info.getValue(),
      header: 'Company',
      size: 200
    }),
    columnHelper.accessor('id', {
      cell: (info) => info.getValue(),
      header: 'Id',
      size: 10
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => (
        <Box align="right" mr="4" w={['100%', '5']}>
          <Button
            onClick={() =>
              window.confirm(
                'hold on for a second. do you want to DELETE that record?'
              ) && onDelete(props)
            }
          >
            <DeleteIcon />
          </Button>
        </Box>
      )
    })
  ];

  return (
    <>
      <InvoicesTopBar
        data={invoices?.aggregate}
        filter={filter}
        onChangeFilter={onChangeFilter}
        onShowModalInvoiceCreate={onShowModalInvoiceCreate}
      />
      <Flex
        css="p-2 block max-w-full overflow-x-hidden overflow-y-hidden"
        overflowX="hidden"
        w="100%"
      >
        <DataTable
          blockOnClickColumns={['actions']}
          columns={columns}
          data={invoices?.invoices}
          onClickRow={onClickRow}
        />
      </Flex>
      <SidebarDrawer
        isOpen={openSidebarDrawer}
        onClose={onCloseSidebar}
        title="Invoice"
      >
        <Invoice id={match?.params?.id} />
      </SidebarDrawer>
    </>
  );
}

InvoicesContent.propTypes = propTypes;
