// from: react-hooks-in-action: useFormState.js
// custom hook to manage form data
import {useEffect, useState} from 'react';

export default function useFormState(data) {
  const [values, setValues] = useState(data);

  useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, [data]);

  function handleChange(e) {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  }

  function handleReset() {
    setValues({});
  }

  return {
    values,
    handleChange,
    handleReset
  };
}
