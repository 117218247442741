import {gql, useQuery} from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import TaskSelectContent from './TaskSelectContent';

export const query = gql`
  query listProject($userid: Int!) {
    projects: project(
      order_by: {name: asc}
      where: {
        project_user: {userid: {_eq: $userid}}
        _and: {archived: {_eq: false}}
      }
    ) {
      id
      name
      name2
      tasks: tasks(order_by: {name: asc}) {
        id
        name
      }
    }
  }
`;

const propTypes = {
  name: PropTypes.string,
  nullValue: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default function TaskSelect({
  name,
  nullValue = 'null',
  onChange,
  value
}) {
  const {data, error, loading} = useQuery(query, {
    variables: {userid: localStorage.getItem('userid')},
    fetchPolicy: 'network-only'
  });

  return (
    <AsyncContent error={error} loading={loading}>
      {() => (
        <TaskSelectContent
          name={name}
          nullValue={nullValue}
          onChange={onChange}
          projects={data?.projects}
          value={value}
        />
      )}
    </AsyncContent>
  );
}

TaskSelect.propTypes = propTypes;
