import {CheckIcon} from '@chakra-ui/icons';
import {Flex, useDisclosure} from '@chakra-ui/react';
import {createColumnHelper} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate, useMatch} from 'react-router-dom';
import {DataTable} from 'components/DataTable/DataTable';
import SidebarDrawer from 'components/SidebarDrawer';
import Project from 'containers/Project';
import Formater from 'utils/Formater';

const propTypes = {
  projects: PropTypes.object
};

export default function ProjectsContent({projects}) {
  const navigate = useNavigate();

  function onClickRow(row) {
    return navigate(`/projects/project/${row.getValue('id')}`);
  }

  const match = useMatch('/projects/project/:id');

  const openSidebarDrawer = match?.params?.id != null;
  useDisclosure({isOpen: openSidebarDrawer});

  function onCloseSidebar() {
    // onClose();
    navigate('/projects');
  }

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('project.name2', {
      cell: (info) => info.getValue(),
      header: 'name2',
      size: 200
    }),
    columnHelper.accessor('totaldurationmin', {
      cell: (info) => Formater.numberFormat(info.getValue() / 60),
      header: 'as-is [h]',
      size: 200
    }),
    columnHelper.accessor('project.budgetmin', {
      cell: (info) => Formater.numberFormat(info.getValue() / 60),
      header: 'budget [h]',
      size: 200
    }),
    columnHelper.accessor('project.archived', {
      cell: (info) => (info.getValue() ? <CheckIcon /> : ''),
      header: 'archived',
      size: 10
    }),
    columnHelper.accessor('id', {
      cell: (info) => info.getValue(),
      header: 'Id',
      size: 10
    })
  ];

  return (
    <>
      <Flex
        css="p-2 block max-w-full overflow-x-hidden overflow-y-hidden"
        overflowX="hidden"
        w="100%"
      >
        <DataTable
          blockOnClickColumns={['actions']}
          columns={columns}
          data={projects?.projects}
          onClickRow={onClickRow}
        />
      </Flex>
      <SidebarDrawer
        isOpen={openSidebarDrawer}
        onClose={onCloseSidebar}
        size="md"
        title="Project"
      >
        <Project id={match?.params?.id} onClose={onCloseSidebar} />
      </SidebarDrawer>
    </>
  );
}

ProjectsContent.propTypes = propTypes;
