import {gql, useQuery} from '@apollo/client';
import React, {useState} from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import ProjectsContent from './ProjectsContent';

export const query = gql`
  query listProjects($where: project_view_bool_exp! = {}, $limit: Int = 2500) {
    aggregate: project_view_aggregate(where: $where) {
      projects: aggregate {
        totalCount: count
      }
    }
    projects: project_view(order_by: {project: {name2: asc}}, where: $where) {
      id
      project {
        name
        name2
        budgetmin
        archived
      }
      totaldurationmin
    }
  }
`;

export default function Projects() {
  const [filter, setFilter] = useState({
    project: undefined,
    from: undefined,
    to: undefined
  });

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  const $listVariables = {
    where: {
      project: {
        company: {
          companyuser: {userid: {_eq: localStorage.getItem('userid')}}
        },
        deleted: {_eq: false}
      }
    }
  };

  const {data, error, loading} = useQuery(query, {
    variables: $listVariables,
    fetchPolicy: 'network-only'
  });

  return (
    <AsyncContent error={error} loading={loading}>
      {() => (
        <>
          <ProjectsContent
            filter={filter}
            onChangeFilter={onChangeFilter}
            projects={data}
          />
        </>
      )}
    </AsyncContent>
  );
}
