import {gql, useQuery, useMutation} from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import {query as queryProjects} from 'containers/Projects/Projects';
import ProjectContent from './ProjectContent';

const queryProject = gql`
  query getProject($id: Int!) {
    project: project_by_pk(id: $id) {
      budgetmin
      deleted
      id
      name
      name2
      archived
    }
  }
`;

const queryUpdateProject = gql`
  mutation updateProject(
    $id: Int!
    $name: String!
    $name2: String!
    $budgetmin: Int!
    $archived: Boolean
  ) {
    update_project_by_pk(
      pk_columns: {id: $id}
      _set: {
        name: $name
        name2: $name2
        budgetmin: $budgetmin
        archived: $archived
      }
    ) {
      id
    }
  }
`;

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func
};

export default function Project({id, onClose}) {
  const {data, error, loading} = useQuery(queryProject, {
    variables: {id},
    fetchPolicy: 'network-only'
  });

  const [onUpdateProjectMutation, {errorUpdate, loadingUpdate}] =
    useMutation(queryUpdateProject);

  async function onUpdateProject(project) {
    return onUpdateProjectMutation({
      variables: project,
      refetchQueries: [
        {
          query: queryProjects,
          variables: {
            project: {
              company: {
                companyuser: {userid: {_eq: localStorage.getItem('userid')}}
              },
              deleted: {_eq: false}
            }
          }
        }
      ]
    });
  }

  function onUpdateProjectFinish() {
    onClose();
  }

  return (
    <AsyncContent
      error={error || errorUpdate}
      loading={loading || loadingUpdate}
    >
      {() => (
        <ProjectContent
          onUpdateProject={onUpdateProject}
          onUpdateProjectFinish={onUpdateProjectFinish}
          project={data?.project}
        />
      )}
    </AsyncContent>
  );
}

Project.propTypes = propTypes;
