import {AddIcon} from '@chakra-ui/icons';
import {Button, useToast, HStack} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import useFormState from 'hooks/useFormState';
import TimeEntryFormItem from './TimeEntryFormItem';

const propTypes = {
  onCreateTimeEntry: PropTypes.func,
  onCreateTimeEntryFinish: PropTypes.func
};

// this is for the quick entry of timeentries
export default function TimeEntryForm({
  onCreateTimeEntry,
  onCreateTimeEntryFinish
}) {
  function getBeginTSProposal() {
    const ts = new Date(Date.now());

    // don't propose the current time so that the user has to enter something; if user don't change value nothing is saved
    return ts.toISOString().substring(0, 11) + '00:00';
  }

  const formState = useFormState();
  const {
    values = {timefrom: getBeginTSProposal()},
    handleChange,
    handleReset
  } = formState;

  const toast = useToast();

  function onSubmit() {
    const timeFrom = values.timefrom;
    // to time allways use today - users can change with the edit function if someone works longer
    const newTimeTo = values.timeto
      ? timeFrom?.substring(0, timeFrom.lastIndexOf('T')) + 'T' + values.timeto
      : values.timeto;

    const timeentry = {
      description: values.description,
      timefrom: values.timefrom,
      timeto: newTimeTo,
      taskid: values.taskid,
      userid: localStorage.getItem('userid')
    };

    return onCreateTimeEntry(timeentry)
      .then(() => {
        toast({
          title: 'Good',
          description: "We've sucessfully saved your data.",
          status: 'success',
          duration: 9000,
          isClosable: true
        });
        handleReset();
        onCreateTimeEntryFinish();
      })
      .catch((e) => {
        toast({
          title: 'Problem',
          description: 'We could not saved your data. ' + e,
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      });
  }

  // Htack w experiment as a mobile first response style: https://chakra-ui.com/docs/styled-system/responsive-styles
  return (
    <>
      <HStack w={[360, '100%']}>
        <TimeEntryFormItem handleChange={handleChange} timeentry={values} />
        <Button colorScheme="blue" onClick={() => onSubmit()}>
          <AddIcon />
        </Button>
      </HStack>
    </>
  );
}

TimeEntryForm.propTypes = propTypes;
