import {Text, HStack, Spacer, Input, Button} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import Formater from 'utils/Formater';

const propTypes = {
  data: PropTypes.object,
  filter: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onShowModalInvoiceCreate: PropTypes.func
};

export default function InvoicesTopBar({
  data,
  filter,
  onChangeFilter,
  onShowModalInvoiceCreate
}) {
  function onChangeFrom(from) {
    onChangeFilter({from});
  }

  function onChangeTo(to) {
    onChangeFilter({to});
  }

  return (
    <>
      <HStack>
        <Button onClick={onShowModalInvoiceCreate} w="full">
          Generate New
        </Button>
        <Input
          name="from"
          onChange={(e) => onChangeFrom(e.target.value)}
          placeholder="Select Date From"
          size="md"
          type="date"
          value={filter.from}
        />
        <Text fontSize="md">-</Text>
        <Input
          name="to"
          onChange={(e) => onChangeTo(e.target.value)}
          placeholder="Select Date To"
          size="md"
          type="date"
          value={filter.to}
        />
        <Text fontSize="md" w="full">
          Records: {data?.invoices?.totalCount}
        </Text>
        <Spacer />
        <Text fontSize="md" w="full">
          Σ Totalsum:&nbsp;
          {Formater.numberFormat(data?.invoices?.totalCount)} CHF+EUR
        </Text>
      </HStack>
    </>
  );
}

InvoicesTopBar.propTypes = propTypes;
