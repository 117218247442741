import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {offsetLimitPagination} from '@apollo/client/utilities';
import {
  ChakraProvider,
  VStack,
  theme,
  Flex,
  Spacer,
  Divider,
  Text,
  useColorModeValue,
  Box
} from '@chakra-ui/react';
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import MainMenu from 'components/MainMenu';
import RequireAuth from 'components/RequireAuth';
import Invoices from 'containers/Invoices';
import Login from 'containers/Login';
import ProjectDeliveries from 'containers/ProjectDeliveries';
import Projects from 'containers/Projects';
import Timeentries from 'containers/Timeentries';
import TimeentriesReports from 'containers/TimeentriesReports';
import {ColorModeSwitcher} from './ColorModeSwitcher';

const apiUrl = process.env.REACT_APP_APIURL;

const requestLink = new HttpLink({
  uri: apiUrl
});

// add the bearer webtoken to the header, so that hasura can forward it
const link = ApolloLink.from([requestLink]);
const authLink = setContext((_, {headers}) => {
  const token = localStorage.getItem('jsonwebtoken');
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  }
  return {};
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    // for offset based pagination of the Timeentries.js
    // console.log('query-fieldname', result); // to get the name of the field
    typePolicies: {
      Query: {
        fields: {
          timeentry_interval_view: offsetLimitPagination()
        }
      }
    }
  }),
  link: authLink.concat(link),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    },
    query: {
      fetchPolicy: 'network-only'
    }
  }
});

function App() {
  const bg = useColorModeValue('green.500', 'green.100');

  return (
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        <Router>
          <Box bg={bg} p="2">
            <Flex>
              <MainMenu />
              <Spacer />
              <ColorModeSwitcher align="right" justifySelf="flex-end" />
            </Flex>
          </Box>
          <Divider mt="2" />

          <VStack align="start" pl="3">
            <Routes>
              <Route
                element={
                  <RequireAuth>
                    <Timeentries />
                  </RequireAuth>
                }
                path="/timeentries"
              />
              <Route
                element={
                  <RequireAuth>
                    <TimeentriesReports />
                  </RequireAuth>
                }
                path="/timeentriesreports"
              />
              <Route
                element={
                  <RequireAuth>
                    <Invoices />
                  </RequireAuth>
                }
                path="/invoices/*"
              />
              <Route
                element={
                  <RequireAuth>
                    <Projects />
                  </RequireAuth>
                }
                path="/projects/*"
              />
              <Route
                element={
                  <RequireAuth>
                    <ProjectDeliveries />
                  </RequireAuth>
                }
                path="/projectdeliveries/*"
              />
              <Route element={<Login />} path="/login" />
              <Route
                element={<Text>save to leave - miss you already</Text>}
                path="/logout"
              />
              <Route
                element={
                  <RequireAuth>
                    <>Main Page after Login</>
                  </RequireAuth>
                }
                path="/"
              />
            </Routes>
          </VStack>
        </Router>
      </ChakraProvider>
    </ApolloProvider>
  );
}

export default App;
