import {gql, useQuery, useMutation} from '@apollo/client';
import React, {useState} from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import InvoiceCreateModal from './InvoiceCreateModal';
import InvoicesContent from './InvoicesContent';

const query = gql`
  query listInvoices($where: invoice_bool_exp! = {}, $limit: Int = 2500) {
    aggregate: invoice_aggregate(where: $where) {
      invoices: aggregate {
        totalCount: count
      }
    }
    invoices: invoice(order_by: {invoicedate: desc, num: desc}, where: $where) {
      customer {
        name
      }
      company {
        title
      }
      invoicedate
      currency
      id
      num
    }
  }
`;

const queryCreateInvoice = gql`
  mutation actionCreateInvoice(
    $customerid: Int!
    $timeentryfilterFrom: String!
    $timeentryfilterTo: String!
  ) {
    actionCreateInvoice(
      customerid: $customerid
      timeentryfilterFrom: $timeentryfilterFrom
      timeentryfilterTo: $timeentryfilterTo
    ) {
      message
      code
    }
  }
`;

const queryDeleteInvoice = gql`
  mutation deleteInvoice($id: Int!) {
    update_invoice_by_pk(pk_columns: {id: $id}, _set: {deleted: true}) {
      id
    }
  }
`;

export default function Invoices() {
  const [filter, setFilter] = useState({
    project: undefined,
    from: undefined,
    to: undefined
  });

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  const $listVariables = {
    where: {
      company: {
        companyuser: {userid: {_eq: localStorage.getItem('userid')}}
      },
      deleted: {_eq: false}
    }
  };

  const {data, error, loading} = useQuery(query, {
    variables: $listVariables,
    fetchPolicy: 'network-only'
  });

  const [onCreateInvoiceMutation, {errorCreateInvoice, loadinCreateInvoice}] =
    useMutation(queryCreateInvoice);

  async function onCreateInvoice(payload) {
    return onCreateInvoiceMutation({
      variables: payload,
      refetchQueries: [
        {
          query,
          variables: $listVariables
        }
      ]
    });
  }

  const [isModalInvoiceCreateOpen, setIsModalInvoiceCreateOpen] =
    useState(false);

  function onShowModalInvoiceCreate() {
    setIsModalInvoiceCreateOpen((prev) => !prev);
  }

  const [onDeleteInvoiceMutation, {errorDelete, loadingDelete}] =
    useMutation(queryDeleteInvoice);

  async function onDeleteInvoice(id) {
    return onDeleteInvoiceMutation({
      variables: {id},
      refetchQueries: [
        {
          query,
          variables: $listVariables
        }
      ]
    });
  }

  return (
    <AsyncContent
      error={error || errorCreateInvoice || errorDelete}
      loading={loading || loadinCreateInvoice || loadingDelete}
    >
      {() => (
        <>
          <InvoicesContent
            filter={filter}
            invoices={data}
            onChangeFilter={onChangeFilter}
            onDeleteInvoice={onDeleteInvoice}
            onShowModalInvoiceCreate={onShowModalInvoiceCreate}
          />

          <InvoiceCreateModal
            isOpen={isModalInvoiceCreateOpen}
            onClose={onShowModalInvoiceCreate}
            onCreateInvoice={onCreateInvoice}
            onCreateInvoiceFinish={onShowModalInvoiceCreate}
            onOverlayClick={() => console.log('tt')}
            payload={{customerid: 0, from: '2022-01-01', to: '2023-12-31'}}
          />
        </>
      )}
    </AsyncContent>
  );
}
