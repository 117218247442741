import {gql, useQuery} from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import ProjectDeliveryContent from './ProjectDeliveryContent';

const query = gql`
  query getProjectDelivery($id: Int!) {
    projectdelivery: project_delivery_by_pk(id: $id) {
      id
      article {
        title
      }
      project_user {
        project {
          name
        }
        userlist {
          longname
        }
        company {
          title
        }
      }
      customer {
        name
        company {
          title
        }
      }
      chargingtype
    }
  }
`;

const propTypes = {
  id: PropTypes.string
};

export default function ProjectDelivery({id}) {
  const {data, error, loading} = useQuery(query, {
    variables: {id},
    fetchPolicy: 'network-only'
  });

  return (
    <AsyncContent error={error} loading={loading}>
      {() => <ProjectDeliveryContent projectdelivery={data?.projectdelivery} />}
    </AsyncContent>
  );
}

ProjectDelivery.propTypes = propTypes;
