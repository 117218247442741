import {
  Text,
  Table,
  Thead,
  Tbody,
  Box,
  Tr,
  Th,
  Td,
  TableContainer,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Divider,
  Button
} from '@chakra-ui/react';
import {triggerBase64Download} from 'common-base64-downloader-react';
import PropTypes from 'prop-types';
import React from 'react';
import InvoiceTimeentries from './InvoiceTimeentries';

const propTypes = {
  invoice: PropTypes.object,
  onGetPdfInvoice: PropTypes.func
};

export default function InvoiceContent({invoice, onGetPdfInvoice}) {
  async function getPdfInvoice() {
    const res = await onGetPdfInvoice();
    console.log('res', res.data.actionGetPdfInvoice.data);
    triggerBase64Download(res.data.actionGetPdfInvoice.data, 'invoice.pdf');
  }

  return (
    <>
      <Text fontSize="lg">Invoice</Text>

      <Button onClick={() => getPdfInvoice()}>Download PDF</Button>
      <Text>Id: {invoice?.id}</Text>
      <Text>From: {invoice?.company?.title}</Text>
      <Text>Date: {invoice?.invoicedate}</Text>
      <Text>Customer: {invoice?.customername}</Text>
      <Text>Customer-Address: {invoice?.customeraddress}</Text>
      <Text>Currency: {invoice?.currency}</Text>
      <br />
      <br />
      <Text fontSize="lg" fontWeight="bold">
        Positions
      </Text>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th w="40%">description</Th>
              <Th isNumeric>priceperpice</Th>
              <Th isNumeric>pricecurrency</Th>
              <Th isNumeric>amountofpieces</Th>
              <Th isNumeric>totalprice</Th>
            </Tr>
          </Thead>
          <Tbody>
            {invoice?.invoice_position.map((item, index) => (
              <Tr key={index}>
                <Td>{index + 1}</Td>
                <Td
                  css={{
                    whiteSpace: 'normal',
                    wordBreak: 'break-word'
                  }}
                >
                  {item.description}
                </Td>
                <Td isNumeric>{item.priceperpiece}</Td>
                <Td isNumeric>{item.pricecurrency}</Td>
                <Td isNumeric>{item.amountofpieces}</Td>
                <Td isNumeric>{item.totalprice}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex fontSize="lg" w="100%">
        <Box align="right" w="90%">
          <Text>Total Net:</Text>
          <Text>VAT ({invoice?.vatratio}):</Text>
          <Text>Total Gross: </Text>
        </Box>

        <Box align="right" ml="1" mr="5" w="10%">
          <Text>{invoice?.totalnet}</Text>
          <Text>{invoice?.vatamount}</Text>
          <Text textDecoration="underline double">{invoice?.totalgross}</Text>
        </Box>
      </Flex>
      <br />
      <Divider />
      <br />
      <Text fontSize="lg" fontWeight="bold">
        Details
      </Text>
      <Accordion allowMultiple defaultIndex={[0]}>
        {invoice?.timeentries.map((position, index) => (
          <AccordionItem key={position.id}>
            <h2>
              <AccordionButton bgColor="gray.100">
                <Box as="span" flex="1" textAlign="left">
                  <Text fontWeight="bold">Position {index + 1}</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <InvoiceTimeentries positiontimeentries={position} />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
}

InvoiceContent.propTypes = propTypes;
