import {Spinner} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from './AsyncContent';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  error: PropTypes.object,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool
};

export default function AsyncContentInline({
  children,
  error,
  loading,
  loadingMore
}) {
  return (
    <AsyncContent
      error={error}
      loading={loading}
      loadingMore={loadingMore}
      renderLoading={() => (
        <div>
          <Spinner size="sm" />
        </div>
      )}
    >
      {children}
    </AsyncContent>
  );
}

AsyncContentInline.propTypes = propTypes;
