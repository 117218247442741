import {DeleteIcon} from '@chakra-ui/icons';
import {Flex, useDisclosure, Box, Button, useToast} from '@chakra-ui/react';
import {createColumnHelper} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate, useMatch} from 'react-router-dom';
import {DataTable} from 'components/DataTable/DataTable';
import SidebarDrawer from 'components/SidebarDrawer';
import ProjectDelivery from 'containers/ProjectDelivery';
// import ProjectDeliveriesTopBar from './TimeentriesReportTopBar';

const propTypes = {
  onDeleteProjectDelivery: PropTypes.func,
  projectdeliveries: PropTypes.object
};

export default function ProjectDeliveriesContent({
  onDeleteProjectDelivery,
  projectdeliveries
}) {
  const navigate = useNavigate();

  function onClickRow(row) {
    return navigate(`/projectdeliveries/${row.getValue('id')}`);
  }

  const match = useMatch('/projectdeliveries/:id');

  const openSidebarDrawer = match?.params?.id != null;
  useDisclosure({isOpen: openSidebarDrawer});

  function onCloseSidebar() {
    // onClose();
    navigate('/projectdeliveries');
  }

  const toast = useToast();

  function onDelete(datatableItem) {
    return onDeleteProjectDelivery(datatableItem.row.getValue('id'))
      .then(() => {
        toast({
          title: 'Good',
          description: "We've sucessfully deleted your charging setup.",
          status: 'success',
          duration: 9000,
          isClosable: true
        });
      })
      .catch((e) => {
        toast({
          title: 'Problem',
          description: 'We could not delete your charging setup. ' + e,
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      });
  }

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('id', {
      cell: (info) => info.getValue(),
      header: 'Id',
      size: 200
    }),
    columnHelper.accessor('chargingtype', {
      cell: (info) => info.getValue(),
      header: 'ChargingType',
      size: 200
    }),
    columnHelper.accessor('project_user.project.name', {
      cell: (info) => info.getValue(),
      header: 'Project',
      size: 200
    }),
    columnHelper.accessor('project_user.userlist.longname', {
      cell: (info) => info.getValue(),
      header: 'Agent',
      size: 200
    }),
    columnHelper.accessor('article.title', {
      cell: (info) => info.getValue(),
      header: 'Article',
      size: 200
    }),
    columnHelper.accessor('customer.name', {
      cell: (info) => info.getValue(),
      header: 'Customer',
      size: 200
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => (
        <Box align="right" mr="4" w={['100%', '5']}>
          <Button
            onClick={() =>
              window.confirm(
                'hold on for a second. do you want to DELETE that record?'
              ) && onDelete(props)
            }
          >
            <DeleteIcon />
          </Button>
        </Box>
      )
    })
  ];

  return (
    <>
      <Flex
        css="p-2 block max-w-full overflow-x-hidden overflow-y-hidden"
        overflowX="hidden"
        w="100%"
      >
        <DataTable
          blockOnClickColumns={['actions']}
          columns={columns}
          data={projectdeliveries?.projectdeliveries}
          onClickRow={onClickRow}
        />
      </Flex>
      <SidebarDrawer
        isOpen={openSidebarDrawer}
        onClose={onCloseSidebar}
        size="xl"
        title="ProjectDelivery"
      >
        <ProjectDelivery id={match?.params?.id} />
      </SidebarDrawer>
    </>
  );
}

ProjectDeliveriesContent.propTypes = propTypes;
