import {Text} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  projectdelivery: PropTypes.object
};

export default function ProjectDeliveryContent({projectdelivery}) {
  return (
    <>
      <Text fontSize="lg">Charging Setup</Text>
      <Text>Id: {projectdelivery?.id}</Text>
      <Text>Project: {projectdelivery.project_user.project.name}</Text>
      <Text>Agent: {projectdelivery.project_user.userlist.longname}</Text>
      <Text>Article: {projectdelivery.article.title}</Text>
      <Text>Customer: {projectdelivery.customer.name}</Text>
    </>
  );
}

ProjectDeliveryContent.propTypes = propTypes;
