import {gql, useMutation, useQuery} from '@apollo/client';
import React, {useState} from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import TimeEntryModal from 'containers/TimeEntry/TimeEntryModal';
import TimeentriesContent from './TimeentriesContent';

// pagination based on: https://www.apollographql.com/docs/react/pagination/offset-based
// inMemory Merge of the new loaded data must be configured in App.js
// after udpate and delete refresh the data (we reload everything at once) (refreshLimit)
// alternative: load only changed data "Cursor-based" pattern would be required.
//  Because apollo needs to know the keys/unique identifier of the data

export const query = gql`
  query timeentryGroupedByDate($userid: Int!, $limit: Int = 5, $offset: Int) {
    aggregate: timeentry_interval_view_aggregate(
      where: {userid: {_eq: $userid}}
    ) {
      timeentry_interval_view: aggregate {
        totalCount: count
      }
    }
    dateintervals: timeentry_interval_view(
      where: {userid: {_eq: $userid}}
      order_by: {numdate: desc_nulls_first}
      limit: $limit
      offset: $offset
    ) {
      numdate
      numweek
      userid
      timeentries: timeentry_view(
        where: {userid: {_eq: $userid}}
        order_by: {timeentry: {timefrom: asc}}
      ) {
        id
        durationmin
        timeentry: timeentry {
          id
          taskid
          timefrom
          timeto
          userid
          description
          task {
            name
            id
            project {
              id
              name
              name2
            }
          }
        }
      }
    }
  }
`;

const queryUpdateTimeEntry = gql`
  mutation updateTimeEntry(
    $id: Int!
    $description: String
    $timefrom: timestamp
    $timeto: timestamp
    $taskid: Int
  ) {
    update_timeentry_by_pk(
      pk_columns: {id: $id}
      _set: {
        description: $description
        taskid: $taskid
        timefrom: $timefrom
        timeto: $timeto
      }
    ) {
      id
    }
  }
`;

const queryDeleteTimeEntry = gql`
  mutation deleteTimeEntry($id: Int!) {
    update_timeentry_by_pk(pk_columns: {id: $id}, _set: {deleted: true}) {
      id
    }
  }
`;

export default function Timeentries() {
  const [refreshLimit, setRefreshLimit] = useState();

  const {data, error, fetchMore, loading} = useQuery(query, {
    variables: {userid: localStorage.getItem('userid'), offset: 0},
    fetchPolicy: 'network-only'
  });

  const [onUpdateTimeEntryMutation, {errorUpdate, loadingUpdate}] =
    useMutation(queryUpdateTimeEntry);

  async function onUpdateTimeEntry(timeentry) {
    return onUpdateTimeEntryMutation({
      variables: timeentry,
      refetchQueries: [
        {
          query,
          variables: {
            userid: localStorage.getItem('userid'),
            offset: 0,
            limit: refreshLimit
          }
        }
      ]
    });
  }

  const [onDeleteTimeEntryMutation, {errorDelete, loadingDelete}] =
    useMutation(queryDeleteTimeEntry);

  async function onDeleteTimeEntry(timeentry) {
    return onDeleteTimeEntryMutation({
      variables: timeentry,
      refetchQueries: [
        {
          query,
          variables: {
            userid: localStorage.getItem('userid'),
            offset: 0,
            limit: refreshLimit - 1
          }
        }
      ]
    });
  }

  const [isModalTimeEntryOpen, setIsModalTimeEntryOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  function onUpdateTimeEntryFinish() {
    setIsModalTimeEntryOpen(false);
  }

  function onToogleModalTimeEntryOpen(item) {
    setSelectedItem(item);
    setIsModalTimeEntryOpen((prev) => !prev);
  }

  function onLoadMoreTimeEntries() {
    const currentLength = data.dateintervals.length;
    fetchMore({
      variables: {
        offset: currentLength,
        limit: 10
      }
    }).then((fetchMoreResult) => {
      // Update variables.limit for the original query to include
      // the newly added items in case of a refresh (after something has been changed)
      setRefreshLimit(
        currentLength + fetchMoreResult.data.dateintervals.length
      );
    });
  }

  return (
    <AsyncContent
      error={error || errorUpdate || errorDelete}
      loading={loading || loadingUpdate || loadingDelete}
    >
      {() => (
        <>
          <TimeentriesContent
            onDeleteTimeEntry={onDeleteTimeEntry}
            onLoadMoreTimeEntries={onLoadMoreTimeEntries}
            onToogleModalTimeEntryOpen={onToogleModalTimeEntryOpen}
            timeentriesGroupedByDate={data}
          />
          <TimeEntryModal
            isOpen={isModalTimeEntryOpen}
            onClose={onToogleModalTimeEntryOpen}
            onOverlayClick={() => console.log('tt')}
            onUpdateTimeEntry={onUpdateTimeEntry}
            onUpdateTimeEntryFinish={onUpdateTimeEntryFinish}
            timeentry={selectedItem}
          />
        </>
      )}
    </AsyncContent>
  );
}
