import {Button, useToast, Text, Input} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'components/Modal';
import CustomersSelect from 'containers/CustomersSelect';
import useFormState from 'hooks/useFormState';

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onCreateInvoice: PropTypes.func,
  onCreateInvoiceFinish: PropTypes.func,
  onOverlayClick: PropTypes.func,
  payload: PropTypes.object
};

export default function InvoiceCreateModal({
  isOpen,
  onClose,
  onCreateInvoice,
  onCreateInvoiceFinish,
  onOverlayClick,
  payload
}) {
  const toast = useToast();

  let isSubmitting = false; // @todo
  const isFormValid = true; // @todo

  function onSubmit(values) {
    const payloadNew = {};
    payloadNew.customerid = values.customerid;
    payloadNew.timeentryfilterFrom = values?.from;
    payloadNew.timeentryfilterTo = values?.to;

    return onCreateInvoice(payloadNew)
      .then(() => {
        toast({
          title: 'success',
          status: 'success'
        });
        isSubmitting = true;
        onCreateInvoiceFinish(payloadNew);
      })
      .catch((e) =>
        toast({
          title: 'error',
          description: 'we could not create the invoice : ' + e,
          status: 'error',
          isClosable: true
        })
      );
  }

  const formState = useFormState(payload);
  const {handleChange, values} = formState;

  return (
    <Modal
      disabled={isSubmitting}
      isOpen={isOpen}
      onClose={onClose}
      onOverlayClick={onOverlayClick || onClose}
      primaryButton={
        <Button
          colorScheme="teal"
          disabled={!isFormValid}
          isLoading={isSubmitting}
          onClick={() => onSubmit(values)}
          type="button"
        >
          save
        </Button>
      }
      scrollBehavior="inside"
      title="Generate New Invoices"
    >
      <Text>generare new Invices, Customer:</Text>
      <CustomersSelect
        name="customerid"
        onChange={handleChange}
        value={values?.customerid}
      />
      <Input
        name="from"
        onChange={handleChange}
        placeholder="Select Date From"
        size="md"
        type="date"
        value={values.from}
      />
      <Text fontSize="md">-</Text>
      <Input
        name="to"
        onChange={handleChange}
        placeholder="Select Date To"
        size="md"
        type="date"
        value={values.to}
      />
      <Text>Select - Customer</Text>
    </Modal>
  );
}

InvoiceCreateModal.propTypes = propTypes;
