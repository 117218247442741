import {Text, HStack, Spacer, Input} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import ProjectsSelect from 'containers/ProjectsSelect';
import Formater from 'utils/Formater';

const propTypes = {
  data: PropTypes.object,
  filter: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};

export default function TimeentriesReportTopBar({
  data,
  filter,
  onChangeFilter
}) {
  function onChangeProject(project) {
    onChangeFilter({project});
  }

  function onChangeFrom(from) {
    onChangeFilter({from});
  }

  function onChangeTo(to) {
    onChangeFilter({to});
  }

  return (
    <>
      <HStack>
        <ProjectsSelect
          onChange={onChangeProject}
          value={filter?.project?.id}
        />
        <Input
          name="from"
          onChange={(e) => onChangeFrom(e.target.value)}
          placeholder="Select Date From"
          size="md"
          type="date"
          value={filter.from}
        />
        <Text fontSize="md">-</Text>
        <Input
          name="to"
          onChange={(e) => onChangeTo(e.target.value)}
          placeholder="Select Date To"
          size="md"
          type="date"
          value={filter.to}
        />
        <Text fontSize="md" w="full">
          Records: {data?.timeentriesview?.totalCount}
        </Text>
        <Spacer />
        <Text fontSize="md" w="full">
          Σ Duration:&nbsp;
          {Formater.numberFormat(data?.timeentriesview?.sum.durationmin / 60)} h
        </Text>
      </HStack>
    </>
  );
}

TimeentriesReportTopBar.propTypes = propTypes;
