import {Flex} from '@chakra-ui/react';
import {createColumnHelper} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import React from 'react';
import {DataTable} from 'components/DataTable/DataTable';
import Formater from 'utils/Formater';
import TimeentriesReportTopBar from './TimeentriesReportTopBar';

const propTypes = {
  filter: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  timeentries: PropTypes.object
};

const columnHelper = createColumnHelper();

function trimDescription(txt) {
  if (txt && txt.length > 1000) {
    return txt.substring(0, 1000) + '...';
  } else {
    return txt;
  }
}

const columns = [
  columnHelper.accessor('timeentry.description', {
    cell: (info) => trimDescription(info.getValue()),
    header: 'Desc',
    size: 400
  }),
  columnHelper.accessor('timeentry.task.project.name2', {
    cell: (info) => info.getValue(),
    header: 'Project',
    size: 200
  }),
  columnHelper.accessor('timeentry.task.name', {
    cell: (info) => info.getValue(),
    header: 'Task',
    size: 200
  }),
  columnHelper.accessor('timeentry.user.longname', {
    cell: (info) => info.getValue(),
    header: 'User',
    size: 120
  }),
  columnHelper.accessor('durationmin', {
    cell: (info) => Formater.numberFormat(info.getValue() / 60),
    header: 'Duration [h]',
    size: 150,
    meta: {
      isNumeric: true
    }
  }),
  columnHelper.accessor('timeentry.timefrom', {
    cell: (info) => info.getValue().slice(0, 10),
    header: 'Date',
    size: 120
  })
];

export default function TimeentriesReportsContent({
  filter,
  onChangeFilter,
  timeentries
}) {
  return (
    <>
      <TimeentriesReportTopBar
        data={timeentries.aggregate}
        filter={filter}
        onChangeFilter={onChangeFilter}
      />
      <Flex
        css="p-2 block max-w-full overflow-x-hidden overflow-y-hidden"
        overflowX="hidden"
        w="100%"
      >
        <DataTable columns={columns} data={timeentries.timeentriesview} />
      </Flex>
    </>
  );
}

TimeentriesReportsContent.propTypes = propTypes;
