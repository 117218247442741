import PropTypes from 'prop-types';
import React from 'react';
import AsyncContentError from './AsyncContentError';
import AsyncContentSpinner from './AsyncContentSpinner';

const propTypes = {
  children: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool
};

export default function AsyncContent({children, error, loading, loadingMore}) {
  if (error) {
    return <AsyncContentError error={error} />;
  }

  if (loading) {
    return <AsyncContentSpinner />;
  }

  if (loadingMore) {
    return (
      <>
        {children()}
        <AsyncContentSpinner />
      </>
    );
  }

  return children();
}

AsyncContent.propTypes = propTypes;
