import {TriangleDownIcon, TriangleUpIcon} from '@chakra-ui/icons';
import {Table, Thead, Tbody, Tr, Th, Td, chakra} from '@chakra-ui/react';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel
} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import React from 'react';
import './DataTable.css';

const propTypes = {
  blockOnClickColumns: PropTypes.array,
  columns: PropTypes.array,
  data: PropTypes.array,
  onClickRow: PropTypes.func
};

export function DataTable({blockOnClickColumns, columns, data, onClickRow}) {
  const [sorting, setSorting] = React.useState();
  const table = useReactTable({
    columns,
    data,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting
    }
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const meta = header.column.columnDef.meta;
              return (
                <Th
                  key={header.id}
                  isNumeric={meta?.isNumeric}
                  style={{position: 'relative', width: header.getSize()}}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}

                  <chakra.span
                    onClick={header.column.getToggleSortingHandler()}
                    pl="4"
                  >
                    {header.column.getIsSorted() === 'desc' ? (
                      <TriangleDownIcon aria-label="sorted descending" />
                    ) : (
                      <TriangleUpIcon aria-label="sorted ascending" />
                    )}
                  </chakra.span>
                  {header.column.getCanResize() && (
                    <chakra.div
                      className={`resizer ${
                        header.column.getIsResizing() ? 'isResizing' : ''
                      }`}
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                    />
                  )}
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Tr key={row.id}>
            {row.getVisibleCells().map((cell) => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const meta = cell.column.columnDef.meta;

              return (
                <Td
                  key={cell.id}
                  isNumeric={meta?.isNumeric}
                  onClick={
                    blockOnClickColumns &&
                    !blockOnClickColumns.includes(cell.column.id)
                      ? () => onClickRow(row)
                      : undefined
                  }
                  style={{width: cell.column.getSize()}}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

DataTable.propTypes = propTypes;
