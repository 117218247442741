import PropTypes from 'prop-types';
import React from 'react';
import {Navigate} from 'react-router-dom';

const propTypes = {
  children: PropTypes.object
};

// from: https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx
export default function RequireAuth({children}) {
  if (!localStorage.getItem('jsonwebtoken')) {
    return <Navigate replace to="/login" />;
  }

  return children;
}

RequireAuth.propTypes = propTypes;
