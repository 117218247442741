import {gql} from '@apollo/client';
import {useMutation} from '@apollo/react-hooks';
// import PropTypes from 'prop-types';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import {query as TimeentriesSelectQuery} from 'containers/Timeentries/Timeentries';
import TimeEntryForm from './TimeEntryForm';

// hasura date time types: https://hasura.io/blog/working-with-dates-time-timezones-graphql-postgresql/
const query = gql`
  mutation addTimeEntry(
    $description: String
    $timefrom: timestamp
    $timeto: timestamp
    $taskid: Int
    $userid: Int
  ) {
    insert_timeentry_one(
      object: {
        description: $description
        timefrom: $timefrom
        timeto: $timeto
        taskid: $taskid
        userid: $userid
      }
    ) {
      id
    }
  }
`;

/*
const propTypes = {
  onCreateTimeEntry: PropTypes.func,
  onCreateTimeEntryFinish: PropTypes.func
};
*/

export default function TimeEntry() {
  const [onCreateTimeEntryMutation, {error, loading}] = useMutation(query);

  async function onCreateTimeEntry(timeentry) {
    return onCreateTimeEntryMutation({
      variables: timeentry,
      refetchQueries: [
        {
          query: TimeentriesSelectQuery,
          variables: {userid: localStorage.getItem('userid')}
        }
      ]
    });
  }

  function onCreateTimeEntryFinish() {
    ///
  }

  return (
    <AsyncContent error={error} loading={loading}>
      {() => (
        <TimeEntryForm
          onCreateTimeEntry={onCreateTimeEntry}
          onCreateTimeEntryFinish={onCreateTimeEntryFinish}
        />
      )}
    </AsyncContent>
  );
}

// TimeEntry.propTypes = propTypes;
